<template>
  <v-app>
    <template v-if="loading && (authenticated || sessionStatusLoadingUser)">
      <page-loading-icon/>
    </template>
    <template v-else-if="!authenticated && !utilityErrorOrAuthExcludingSignInPage">
        <auth-layout>
          <transition name="fade" mode="out-in">
            <portal-signin-page></portal-signin-page>
          </transition>
        </auth-layout>
    </template>
    <template v-else>
      <keep-alive>
        <component :is="currentLayout">
          <transition name="fade" mode="out-in">
            <router-view/>
          </transition>
        </component>
      </keep-alive>
    </template>
    <idle-timeout-dialog v-if="authenticated && !utilityErrorOrAuth"/>
    <snackbar/>
  </v-app>
</template>

<script lang="ts">
// Layouts
import DefaultLayout from "@portal/layouts/DefaultLayout.vue";
import SimpleLayout from "@shared/layouts/SimpleLayout.vue";
import AuthLayout from "@portal/layouts/AuthLayout.vue";
import ErrorLayout from "@shared/layouts/ErrorLayout.vue";
import {BroadcastChannel} from 'broadcast-channel';

import {computed, defineComponent, onBeforeMount, watch,} from "@vue/composition-api";
import {
  getAllProviders,
  provideRouter,
  useAccountService,
  useMaintenanceService,
  useUIService,
} from "@shared/providers";
import router from "@portal/router";
import store, {PatientPortalInfo, RootGetters} from "@shared/store";
import {SessionActions, SessionGetters, SessionMutations} from "@shared/store/session";
import PortalSigninPage from "@portal/pages/auth/PortalSigninPage.vue";
import Snackbar from "@main/components/notifications/Snackbar.vue";
import {UIActions} from "@shared/store/ui-customizations";
import {
  APP_LOCALSTORAGE_KEY,
  BROADCAST_CHANNEL_OPTIONS,
  PORTAL_APP_SESSION_BROADCAST_CHANNEL,
  SESSION_EXPIRY_DATE,
  SessionStatuses,
  SIGN_OUT
} from "@shared/store/constants";
import LoadingIcon from "@main/components/common/LoadingIcon.vue";
import PageLoadingIcon from "@main/components/common/PageLoadingIcon.vue";
import {ClientSubscriptionsGetters} from "@shared/store/financials/client-subscriptions";
import IdleTimeoutDialog from "@main/components/dialogs/IdleTimeoutDialog.vue";
import {InputFieldsGetters} from "@shared/store/input-fields";
import Vue from "vue";
import moment from "moment";
import {MaintenanceActions, MaintenanceGetters} from "@shared/store/maintenance";
import {shouldCheckForMaintenanceFile} from "@shared/functions/dateFunctions";
/*
|---------------------------------------------------------------------
| Main Application Component
|---------------------------------------------------------------------
|
| In charge of choosing the layout according to the router metadata
|
*/
export default defineComponent({
  name: "PortalApp",
  components: {
    PageLoadingIcon,
    DefaultLayout,
    SimpleLayout,
    AuthLayout,
    ErrorLayout,
    PortalSigninPage,
    Snackbar,
    LoadingIcon,
    IdleTimeoutDialog,
  },
  head: {},
  setup() {
    provideRouter(router); // Dependency Injection
    getAllProviders(); // Dependency Injection

    const uIService = useUIService();
    const accountService = useAccountService();
    const maintenanceService = useMaintenanceService();
    const patientPortalInfo = computed<PatientPortalInfo>(
        () =>
            store.getters[SessionGetters.PATIENT_PORTAL_INFO]
    );
    const authenticated = computed<boolean>(
        () => store.getters[SessionGetters.AUTHENTICATED]
    );
    const sessionLoading = computed<boolean>(
        () => store.getters[SessionGetters.LOADING]
    );
    const sessionStatus = computed<string | null>(
        () => store.getters[SessionGetters.STATUS]
    );
    const appLoading = computed<boolean>(
        () => store.getters[RootGetters.LOADING]
    );
    const subscriptionsLoading = computed<boolean>(
        () => store.getters[ClientSubscriptionsGetters.LOADING]
    );
    const inputFieldsLoading = computed<boolean>(
        () => store.getters[InputFieldsGetters.LOADING]
    );
    const loading = computed<boolean>(
        () => appLoading.value || sessionLoading.value
    );
    const sessionStatusLoadingUser = computed<boolean>(() =>
        store.getters[SessionGetters.STATUS]?.includes(
            SessionStatuses.LOADING_USER_STARTED,
            SessionStatuses.VALIDATING_TEXT_CODE_STARTED,
            SessionStatuses.LOADING_USER_SUCCESSFUL,
            SessionStatuses.LOADING_USER_FAILED,
            SessionStatuses.NO_STATUS
        )
    );

    const currentLayout = computed<string>(
        () => ((router?.app?.$route?.meta as any)?.layout || "Simple") + "Layout"
    );

    const utilityErrorOrAuthExcludingSignInPage = computed<boolean>(
        () =>
            !!(
                router.app.$route?.name?.startsWith("utility-") ||
                router.app.$route?.name?.startsWith("error-") ||
                (router.app.$route?.name?.startsWith("auth-") &&
                    router.app.$route.name.toString() !== "auth-signin")
            )
    );

    const utilityErrorOrAuth = computed<boolean>(
        () =>
            !!(
                router.app.$route?.name?.startsWith("utility-") ||
                router.app.$route?.name?.startsWith("error-") ||
                router.app.$route?.name?.startsWith("auth-")
            )
    );

    async function reload() {
        await clearCache();
        await router.go;
        return;
    }

    const bc = new BroadcastChannel(PORTAL_APP_SESSION_BROADCAST_CHANNEL, BROADCAST_CHANNEL_OPTIONS);

    bc.onmessage = async (messageEvent) => {
      if (!messageEvent) {
        return;
      }
      const {
        sessionStatus,
        diff
      } = typeof messageEvent?.sessionStatus === 'string' && typeof messageEvent?.diff === 'boolean' ? messageEvent : {
        sessionStatus: SessionStatuses.NO_STATUS,
        diff: false
      };

      const storageItem = localStorage.getItem(APP_LOCALSTORAGE_KEY);
      const commonData = storageItem ? JSON.parse(storageItem) : {};
      await Vue.nextTick();

      if (commonData?.SessionModule?.xsrfToken && commonData?.SessionModule?.xsrfToken !== store.getters[SessionGetters.XSRF_TOKEN]) {
        store.commit(SessionMutations.SET_XSRF_TOKEN, commonData?.SessionModule?.xsrfToken);
      }

      if (!store.getters[SessionGetters.AUTHENTICATED] && sessionStatus === SessionStatuses.NO_STATUS) {
        store.commit(SessionMutations.SET_USER, commonData?.SessionModule?.user);
        store.commit(SessionMutations.SET_PATIENT_PORTAL_INFO, commonData?.SessionModule?.patientPortalInfo);
        if(diff) await reload();
        return;
      }

      if (sessionStatus?.startsWith(SIGN_OUT) && store.getters[SessionGetters.USER]) {
        store.commit(SessionMutations.SET_USER, undefined);
        store.commit(SessionMutations.SET_STATUS, SessionStatuses.SIGN_OUT_SUCCESSFUL);
        return;
      }
    }

    onBeforeMount(async () => {
      await store.dispatch(UIActions.LOAD_GLOBAL_THEME, {
        service: uIService,
      });
      await store.dispatch(UIActions.SAVE_BROWSER, {
        navigator: navigator,
      });

      if(shouldCheckForMaintenanceFile() && (store.getters[MaintenanceGetters.EXISTS] || !store.getters[MaintenanceGetters.REQUESTED])) {
        await store.dispatch(MaintenanceActions.LOAD_MAINTENANCE_FILE, {
          service: maintenanceService,
        });

        await Vue.nextTick();
        if(store.getters[MaintenanceGetters.EXISTS]) {
          await router.replace({name: "utility-maintenance"});
        }
      }

      //signout user if session is already expired to save errors from printing on screen
      if (store.getters[SessionGetters.AUTHENTICATED]) {
        const currentExpiry = localStorage.getItem(SESSION_EXPIRY_DATE);
        if (!currentExpiry || (moment.utc(currentExpiry).isValid() && moment.utc(currentExpiry).isBefore(moment.utc()))) {
          await store.dispatch(SessionActions.SIGN_OUT, {
            service: accountService,
          });
        }
      }
    });

    async function clearCache() {
      store.cache.clear();
      await store.dispatch("reset");
    }

    watch(
        sessionStatus,
        async (currentStatus, prevStatus) => {
          if (currentStatus === prevStatus) {
            return;
          }
          switch (currentStatus) {
            case SessionStatuses.LOGIN_SUCCESSFUL:
            case SessionStatuses.LOADING_USER_SUCCESSFUL:
              await Vue.nextTick();
              await store.commit(SessionMutations.SET_STATUS, SessionStatuses.NO_STATUS);
              if(router.app.$route?.path === "/") {
                await router.replace({name: 'patient-portal-payment-options-overview'});
              }

              break;
            case SessionStatuses.SIGN_OUT_FAILED:
            case SessionStatuses.SIGN_OUT_SUCCESSFUL:
              await clearCache();
              break;
          }
        },
        {immediate: true}
    );

    return {
      authenticated,
      sessionLoading,
      appLoading,
      utilityErrorOrAuthExcludingSignInPage,
      loading,
      currentLayout,
      sessionStatus,
      sessionStatusLoadingUser,
      subscriptionsLoading,
      inputFieldsLoading,
      utilityErrorOrAuth,
      patientPortalInfo,
    };
  },
});
</script>

<style lang="scss">
/**
 * Transition animation between pages
 */
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.25s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
</style>
