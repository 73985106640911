<template>
  <div class="d-flex text-center flex-column flex-md-row flex-grow-1">
    <div
      class="pa-2 pa-md-4 flex-grow-1 align-center justify-center d-flex flex-column"
    >
      <div class="layout-content ma-auto w-full">
        <slot></slot>
      </div>
      <div>
        <span class="caption">powered by
          <span class="overline">{{ APP_NAME }}</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {defineComponent} from "@vue/composition-api";
import {APP_NAME} from "@shared/store/constants";

export default defineComponent({
  name: "AuthLayout",
  setup() {
    return {
      APP_NAME,
    }
  }
});
</script>

<style scoped lang="scss">
.layout-side {
  width: 420px;
}

.layout-content {
  max-width: 480px;
}
</style>
