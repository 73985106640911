<template>
  <div>
    <!-- Navigation -->
    <v-navigation-drawer
      v-model="drawer"
      app
      floating
      class="elevation-1"
      :right="$vuetify.rtl"
      :light="true"
    >
      <!-- Navigation menu info -->
      <template v-slot:prepend>
        <div class="pa-2 pb-0">
          <div class="title font-weight-bold text-uppercase primary--text">
            {{ contactInfo && contactInfo.name ? contactInfo.name : product.name }}
          </div>
          <div class="overline grey--text d-flex justify-space-between"><span
            v-if="contactInfo && contactInfo.name">{{ product.name }}</span><span>{{
              product.version
            }}</span></div>
        </div>
      </template>
      <main-menu :dashboard="directionsMenu" :menu="navigation.menu" />
      <main-sub-menu title="Signing Documents"
                     disableExpansion
                     :menu="[sideNav]"
                     :defaultExpanded="true"
                     :defaultSubMenuExpanded="true"
                     key="signing-documents">
      </main-sub-menu>
      <!-- Navigation menu footer -->
      <template v-slot:append>
        <!-- Footer navigation links -->
        <div class="pa-1 text-center">
          <v-btn
            v-for="(item, index) in navigation.footer"
            :key="index"
            @click="navigateTo(item.link)"
            small
            block
            text
          >
            {{ item.key ? item.key : item.text }}
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <!-- Toolbar -->
    <v-app-bar
      app
      :color="isToolbarDetached ? 'surface' : undefined"
      :flat="isToolbarDetached"
      :light="true"
    >
      <v-card
        class="flex-grow-1 d-flex"
        :class="[isToolbarDetached ? 'pa-1 mt-3 mx-1' : 'pa-0 ma-0']"
        :flat="!isToolbarDetached"
      >
        <div class="d-flex flex-grow-1 align-center">

          <div class="d-flex flex-grow-1 align-center">
            <v-app-bar-nav-icon text
                                @click.stop="drawer = !drawer"
            ></v-app-bar-nav-icon>
            <v-spacer></v-spacer>
            <toolbar-help></toolbar-help>
            <toolbar-user />
          </div>
        </div>
      </v-card>
    </v-app-bar>

    <v-main>
      <v-container
        :fluid="!isContentBoxed"
        :class="{ 'mt-1 pa-0 py-2': $vuetify.breakpoint.mobile }"
      >

        <v-layout>
          <slot></slot>
        </v-layout>
      </v-container>
    </v-main>
  </div>
</template>

<script lang="ts">
// navigation menu configurations
import config from "@portal/configs/index";
import ToolbarUser from "@main/components/toolbar/ToolbarUser.vue";
import theme from "@main/configs/theme";
import { computed, defineComponent, onBeforeMount } from "@vue/composition-api";
import {
  useCalculationRulesService,
  usePatientContractsService,
  usePatientService,
  usePatientTreatmentPlanAdditionalChargesService,
  usePatientTreatmentPlanDiscountsService,
  usePatientTreatmentPlansService,
  useRouter,
  useStore
} from "@shared/providers";
import { useRouteComponents } from "@main/composables/helpers";
import { PatientsActions, PatientsGetters } from "@shared/store/patients";
import { PatientContractsActions } from "@shared/store/documents/patient-contracts";
import { CalculationRule, NavMenu, PatientPortalInfo, TreatmentPlan, User } from "@shared/store/interfaces";
import { ContactInfo } from "@shared/store/practice/offices/interfaces";
import { OfficesGetters } from "@shared/store/practice/offices";
import MainMenu from "@main/components/navigation/MainMenu.vue";
import MainSubMenu from "@main/components/navigation/MainSubMenu.vue";
import { CalculationRuleTypes } from "@shared/store/constants";
import { CalculationRulesActions, CalculationRulesGetters } from "@shared/store/calculations/calculation-rules";
import { cloneDeep } from "lodash";
import { ResponsibleParty } from "@shared/store/responsible-parties/interfaces";
import { PatientTreatmentPlansActions, PatientTreatmentPlansGetters } from "@shared/store/treatment-plans";
import { SessionGetters } from "@shared/store/session";
import { usePatientTxPlanAndContractsInfo } from "@main/composables/helpers/use-patient-tx-plan-and-contracts-info";
import ToolbarHelp from "@portal/components/toolbars/ToolbarHelp.vue";

export default defineComponent({
  name: "DefaultLayout",
  components: {
    MainMenu,
    MainSubMenu,
    ToolbarUser,
    ToolbarHelp
  },
  setup() {
    const { navigateTo, routeName } = useRouteComponents();
    const router = useRouter();
    const drawer = null;
    let navigation = config.navigation;
    let directionsMenu = config.directions?.items;
    let paymentOptionsMenu = config.paymentOptions;
    let signingDocumentsMenu = config.signingDocuments;
    let signingDocumentsOptionalMenuItem = config.signingDocumentsOptional;
    const product = config.product;
    const isContentBoxed = theme.isContentBoxed;
    const menuTheme = theme.menuTheme;
    const isToolbarDetached = theme.isToolbarDetached;
    const store = useStore();
    const calcRulesService = useCalculationRulesService();
    const patientContractsService = usePatientContractsService();
    const patientService = usePatientService();
    const treatmentPlanService = usePatientTreatmentPlansService();
    const patientTreatmentPlanDiscountsService = usePatientTreatmentPlanDiscountsService();
    const patientTreatmentPlanAdditionalChargesService = usePatientTreatmentPlanAdditionalChargesService();

    const contactInfo = computed<ContactInfo>(
      () => store.getters[OfficesGetters.CONTACT_INFO]
    );

    const calculationRules = computed<CalculationRule[]>(
      () => store.getters[CalculationRulesGetters.CALCULATION_RULES]
    );

    const canEsignAfterSliders = computed<boolean>(() => {
        const canSign = calculationRules?.value?.find(c => c?.calculationRuleTypeId === CalculationRuleTypes.CAN_ESIGN_AFTER_SLIDERS);
        return canSign?.amount == 1;
      }
    );

    const {
      areContractsFixed
    } = usePatientTxPlanAndContractsInfo();


    const patientPortalInfo = computed<PatientPortalInfo>(
      () => store.getters[SessionGetters.PATIENT_PORTAL_INFO]
    );

    const selectedTreatmentPlan = computed<TreatmentPlan>(
      () => store.getters[PatientTreatmentPlansGetters.SELECTED_PATIENT_TREATMENT_PLAN]
    );

    const selectedResponsiblePartyId = computed<string | undefined>(
      () => {
        const patient = store.getters[PatientsGetters.SELECTED_PATIENT];
        if (patient?.patientResponsibleParties?.length > 0) {
          let rpIndex = patient?.patientResponsibleParties?.length === 1
            ? 0
            : patient?.patientResponsibleParties?.findIndex((x: ResponsibleParty) => x.email?.toLowerCase() === user.value?.email?.toLowerCase() || (x.firstName?.toLowerCase() === user.value?.firstName?.toLowerCase() && x.lastName?.toLowerCase() === user.value?.lastName?.toLowerCase()));
          if (rpIndex > -1 && patient?.patientResponsibleParties[rpIndex]?.patientResponsiblePartyId) {
            return patient?.patientResponsibleParties[rpIndex].patientResponsiblePartyId;
          }
        }
        return undefined;
      }
    );

    const sideNav = computed<NavMenu | undefined>(
      () => {
        let sideNav = cloneDeep(signingDocumentsMenu) as NavMenu;
        let menuItem = signingDocumentsOptionalMenuItem;
        if (!canEsignAfterSliders.value || !selectedResponsiblePartyId.value) {
          return sideNav;
        }

        if (areContractsFixed.value) {
          menuItem.disabled = false;
        }

        sideNav?.items?.push(signingDocumentsOptionalMenuItem);
        return sideNav;
      }
    );

    const user = computed<User>(() => store.getters[SessionGetters.USER]);

    onBeforeMount(async () => {
      if (!routeName.value?.startsWith("patient") || routeName.value === "patient-portal-directions" && patientPortalInfo.value.patientTreatmentPlanId) {
        const preFetchDataPromises: Promise<any>[] = [];
        preFetchDataPromises.push(store.cache.dispatch(PatientTreatmentPlansActions.LOAD_SELECTED_TREATMENT_PLAN, {
            id: patientPortalInfo.value.patientTreatmentPlanId,
            service: treatmentPlanService,
            patientTreatmentPlanDiscountsService: patientTreatmentPlanDiscountsService,
            patientTreatmentPlanAdditionalChargesService: patientTreatmentPlanAdditionalChargesService
          }
        ));
        preFetchDataPromises.push(store.cache.dispatch(
          CalculationRulesActions.LOAD_CALCULATION_RULES,
          {
            service: calcRulesService
          }
        ));
        preFetchDataPromises.push(store.cache.dispatch(PatientContractsActions.LOAD_PATIENT_CONTRACTS, {
            id: patientPortalInfo.value.patientTreatmentPlanId,
            service: patientContractsService,
            suppressNotification: false
          }, {
            timeout: 30000
          }
        ));

        await Promise.all(preFetchDataPromises);

        if (selectedTreatmentPlan.value?.patientId) {
          await store.cache.dispatch(PatientsActions.GET_PATIENT_DETAIL, {
            service: patientService,
            treatmentPlanService,
            id: selectedTreatmentPlan.value?.patientId
          });
        }
      }
    });


    return {
      drawer,
      navigation,
      product,
      isContentBoxed,
      menuTheme,
      isToolbarDetached,
      navigateTo,
      routeName,
      calculationRules,
      canEsignAfterSliders,
      sideNav,
      contactInfo,
      directionsMenu,
      paymentOptionsMenu,
      signingDocumentsMenu,
      selectedResponsiblePartyId,
      areContractsFixed
    };
  }
});
</script>
