<template>
    <div>
        <v-card class="text-center pa-1"
                v-shortkey="['enter']"
                @shortkey.prevent="submit">
            <v-card-title class="justify-center display-1 mb-2">Welcome</v-card-title>
            <v-card-subtitle>Patient Portal Sign In</v-card-subtitle>

            <!-- sign in form -->
            <v-card-text>
                <v-form
                        v-model="valid"
                        ref="value"
                        @submit.prevent="submit"
                        :disabled="loading"
                >
                    <v-text-field
                            label="Patient Last Name"
                            name="lastName"
                            counter="100"
                            outlined
                            autofocus
                            :disabled="loading"
                            v-model.trim="fields.lastName"
                            :rules="rules.name"
                    ></v-text-field>
                    <date-field
                            v-model="fields.birthDate"
                            :disable="loading"
                            required
                            outlined
                            label="Patient Birthday"
                            :dense="false"
                            class="mb-1"
                            validate-on-blur
                    />
                    <v-btn
                            :loading="loading && statusLoggingIn"
                            :disabled="loading"
                            block
                            x-large
                            color="primary"
                            @click="submit"
                    >Login
                    </v-btn
                    >
                    <div class="mt-5">
                        <router-link to="/contact-us" :class="{'disabled' :loading}">
                            Are you having trouble signing in?
                        </router-link>
                    </div>
                </v-form>
            </v-card-text>
        </v-card>
    </div>
</template>

<script lang="ts">
/*
|---------------------------------------------------------------------
| Sign In Page Component
|---------------------------------------------------------------------
|
| Sign in template for user authentication into the application
|
*/
import {computed, defineComponent, reactive, ref, toRefs,} from "@vue/composition-api";
import {useStore} from "@shared/providers/use-store";
import {useRouter} from "@shared/providers/use-router";
import {SessionActions, SessionGetters} from "@shared/store/session";
import {useAccountService} from "@shared/providers/use-account-service";
import {FormDefinition} from "@main/components/forms/interfaces";
import {VForm} from "@main/components/forms/types";
import {maxLengthRule, regexNameRule, requiredRule,} from "@main/composables/validation";
import {SessionStatuses} from "@shared/store/constants";
import Vue from "vue";
import {PatientLoginCredentials} from "@shared/store";
import DateField from "@main/components/forms/common/fields/DateField.vue";
import {useRouteComponents} from "@main/composables/helpers";


interface SigninForm extends FormDefinition {
    value: VForm | null;
    valid: boolean;
    fields: {
        lastName: string;
        birthDate: string;
    };
    rules: {
        name: ((message?: string) => {})[];
    };
}

export default defineComponent({
    name: "PortalSigninPage",
    components: {DateField},
    setup() {
        const store = useStore();
        const router = useRouter();
        const service = useAccountService();
        const loading = computed<boolean>(
            () => store.getters[SessionGetters.LOADING]
        );
        const {routeParams, routeName, routeQuery} = useRouteComponents();

        const statusLoggingIn = computed<boolean>(
            () =>
                store.getters[SessionGetters.STATUS] === SessionStatuses.LOGIN_STARTED
        );

        const treatmentPlanId = computed<string | undefined | null>(
            () =>
                routeQuery?.value?.treatmentPlanId || routeParams?.value?.treatmentPlanId || store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.patientTreatmentPlanId
        );

        const code = computed<string | undefined | null>(
            () =>
                routeQuery?.value?.code || store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.code
        );

        const userId = computed<string | undefined | null>(
            () =>
                routeQuery?.value?.id || store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.userId
        );

        const isEmail = computed<boolean>(
            () =>
                routeQuery?.value?.isEmail?.toString() === '1' || !!store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.isEmail
        );

      const isReadOnly = computed<boolean>(
            () =>
                routeQuery?.value?.isReadOnly?.toString() === '1'
                || !!store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.isReadOnly
                //TODO remove this after 11/20/23 since it was to make it backwards compatible with out sent out links
                || routeQuery?.value?.isReadonly?.toString() === '1'
                || !!store.getters[SessionGetters.PATIENT_PORTAL_INFO]?.isReadonly
          // section to remove above

        );
        const status = computed<string | undefined>(
            () =>
                store.getters[SessionGetters.STATUS]
        );
        const showPassword = ref<boolean>(false);
        const form = reactive<SigninForm>({
            value: null,
            valid: false,
            fields: {
                lastName: "",
                birthDate: "",
            },
            rules: {
                name: [requiredRule(), maxLengthRule(100), regexNameRule()],
            },
        });

        async function submit() {
            await Vue.nextTick();
            form.value?.validate();
            await Vue.nextTick();
            if (form.valid) {
                const values = {
                    ...form.fields,
                };
                if (!treatmentPlanId.value || !code.value || !userId.value) {
                    await router.push({
                        name: "error-patient-portal-invalid-link",
                    });
                }

                await store.dispatch(SessionActions.PATIENT_LOGIN, {
                    body: {
                        lastName: values.lastName,
                        birthDate: values.birthDate,
                        patientTreatmentPlanId: treatmentPlanId.value,
                        code: code.value,
                        userId: userId.value,
                        isEmail: isEmail.value,
                      isReadOnly: isReadOnly.value,
                    } as PatientLoginCredentials,
                    service,
                });

                if (status.value === SessionStatuses.LOGIN_SUCCESSFUL) {
                    form?.value?.reset();
                }
            } else {
                form.value?.validate();
            }
        }

        return {
            showPassword,
            ...toRefs(form),
            statusLoggingIn,
            submit,
            loading,
            requiredRule,
            regexNameRule,
            routeParams, routeName, routeQuery,
            status,
        };
    },
});
</script>
<style lang="scss" scoped>
.disabled {
  opacity: 0.5;
  pointer-events: none;
}

::v-deep .v-text-field--enclosed .v-input__append-inner {
  margin-top: 10px;
}
</style>