import Vue from "vue";
import VueCompositionApi from "@vue/composition-api";
import App from "@/PortalApp.vue";

// VUEX - https://vuex.vuejs.org/
import store from "@shared/store";

// VUE-ROUTER - https://router.vuejs.org/
import router from "@portal/router";

// PLUGINS
import vuetify from "@shared/plugins/vuetify";
import "@shared/plugins/vue-shortkey";
import "@shared/plugins/vue-head";
import "@shared/plugins/animate";
import "@shared/plugins/clipboard";
import "@shared/plugins/moment";
import "@shared/plugins/maska";
import "@shared/plugins/vue2-perfect-scrollbar";
import "@shared/plugins/vue-debounce";
import "@shared/plugins/v-sanitize";

// DIRECTIVES
import '@shared/directives/blur';


// FILTERS
import "@shared/filters/capitalize";
import "@shared/filters/lowercase";
import "@shared/filters/uppercase";
import "@shared/filters/formatCurrency";
import "@shared/filters/formatDate";
import "@shared/filters/formatDateTime";
import "@shared/filters/ellipse";

// EXTENSIONS
import "@shared/extensions/string";

// STYLES
// Main Theme SCSS
import "@shared/assets/scss/theme.scss";

// Animation library - https://animate.style/
import "animate.css/animate.min.css";

// Set this too false to prevent the production tip on Vue startup.
Vue.config.productionTip = false;

/*
|---------------------------------------------------------------------
| Main Vue Instance
|---------------------------------------------------------------------
|
| Render the vue application on the <div id="app"></div> in index.html
|
| https://vuejs.org/v2/guide/instance.html
|
*/

//TODO convert these to plugins
Vue.use(VueCompositionApi);

export default new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
